import { type FC, useRef, act } from 'react';
import ReactPlayer from 'react-player';
import useStreamHost from './hooks/useStreamHost';

const Host: FC<{ room: string }> = ({ room }) => {
	const playerRef = useRef<ReactPlayer>(null);
	const { activeItem, sendMessage, playing } = useStreamHost(room, playerRef);
	return (
		<main className="fixed inset-0 flex items-center justify-center bg-black">
			{activeItem ? (
				<ReactPlayer
					ref={playerRef}
					width="100%"
					height="100%"
					url={activeItem.url}
					playing={playing}
					onPlay={() => sendMessage({ type: 'STARTED', data: { id: activeItem.id } })}
					onPause={() => sendMessage({ type: 'PAUSED', data: { id: activeItem.id } })}
					onReady={() => {
						if (playerRef.current && activeItem.secondsPlayed)
							playerRef.current.seekTo(activeItem.secondsPlayed, 'seconds');
						sendMessage({ type: 'READY', data: { id: activeItem.id } });
					}}
					onDuration={duration =>
						sendMessage({
							type: 'DURATION',
							data: { id: activeItem.id, seconds: duration }
						})
					}
					onStart={() => sendMessage({ type: 'STARTED', data: { id: activeItem.id } })}
					onProgress={({ playedSeconds }) =>
						sendMessage({
							type: 'PROGRESS',
							data: { id: activeItem.id, seconds: playedSeconds }
						})
					}
					onEnded={() => sendMessage({ type: 'ENDED', data: { id: activeItem.id } })}
					onError={e => console.error('error', e)}
				/>
			) : (
				<>
					<video
						className="fixed inset-0 h-screen w-screen object-cover object-center"
						src="/clouds.mp4"
						autoPlay
						muted
						loop
					/>
					<div className="relative z-10 mix-blend-lighten">
						<img src="/splash.webp" width={300} height={300} />
					</div>
				</>
			)}
		</main>
	);
};

export default Host;
