import { createRoot } from 'react-dom/client';
import { type FC } from 'react';
import Host from './Host';

const App: FC = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const room = queryParams.get('room') ?? 'debug';
	return <Host room={room} />;
};

createRoot(document.getElementById('app')!).render(<App />);
